import { MouseEvent, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
type SubnavLink = {
  icon?: {
    url?: string;
  };
  text?: string;
  LinkHoverColor?: string;
  subtext?: string;
  link?: {
    href: string;
    onClick(event: MouseEvent): void;
    target?: '_self' | '_blank';
  };
};
type NavigationContentSingleColumnProps = {
  subtitle?: string;
  divider?: boolean;
  subnavLinks?: SubnavLink[];
};
export function NavigationContentSingleColumn({
  subtitle,
  divider,
  subnavLinks
}: NavigationContentSingleColumnProps) {
  const [hoveredLink, setHoveredLink] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = (currentLink: number) => {
    setIsHovered(true);
    setHoveredLink(currentLink);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return <div className="z-10" data-testid="navigation-content-single-column" data-sentry-component="NavigationContentSingleColumn" data-sentry-source-file="NavigationContentSingleColumn.tsx">
			<ul className="shrink-0 min-w-[140px] flex flex-col items-start">
				<li className="uppercase  text-eyebrow mb-2 text-[12px] font-medium">{subtitle}</li>
				{divider && <div className="bg-gray-1000 h-[1px] w-full"></div>}
				{subnavLinks?.map((subnavLink, i) => <li key={i} className="pt-5" onMouseEnter={() => handleMouseEnter(i)} onMouseLeave={handleMouseLeave}>
						<Link href={subnavLink.link?.href ?? '#'} onClick={subnavLink.link?.onClick} target={subnavLink.link?.target} className="group/link block">
							<span style={{
            color: isHovered && hoveredLink === i ? subnavLink.LinkHoverColor : ''
          }} className="flex items-start text-xs leading-[19.2px] pb-.5 font-medium text-bc-black link-column-click">
								{subnavLink.icon?.url && <Image src={subnavLink.icon.url.startsWith('//') ? `https:${subnavLink.icon.url}` : subnavLink.icon.url} alt="icon" width="18" height="16" className="mr-2" />}
								{subnavLink.text}
							</span>
							{subnavLink.subtext && <div className="mt-2 text-xs text-gray-500 font-normal leading-[18px] link-column-click">
									{subnavLink.subtext}
								</div>}
						</Link>
					</li>)}
			</ul>
		</div>;
}